import ReactDOM from 'react-dom'
import React from 'react'
// import Bugsnag from '@bugsnag/js'
// import BugsnagPluginReact from '@bugsnag/plugin-react'

import Layout from './src/components/Layout'
import Header from './src/components/Header'
import Footer from './src/components/Footer'

import Overlay from './src/components/Overlay'
import Lightbox from './src/components/Lightbox'

const transitionDelay = 100
const { loadableReady } = require('@loadable/component')

// Bugsnag.start({
//   apiKey: process.env.BUGSNAG_API_KEY,
//   plugins: [new BugsnagPluginReact()],
// })



export const replaceHydrateFunction = () => (element, container, callback) => {
  loadableReady(() => {
    ReactDOM.render(element, container, callback)
  })
}

export const wrapPageElement = ({ element, props }) => {
  // const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

  return (
  <>
    <Header location={props.location || false} />
    <Layout {...props}>{element}</Layout>
    <Overlay />
    <Lightbox />

    <Footer />
  </>
)}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    )
  }
  return false
}
