import moment from "moment"
import add from "date-fns/add"

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

export const getToday = () => {
  const dayInt = moment().tz("Europe/Rome").day()

  return days[dayInt]
}

export const getTomorrow = () => {
  const dayInt = moment().tz("Europe/Rome").day()
  const tomInt = dayInt + 1 === 7 ? 0 : dayInt + 1

  return days[tomInt]
}

export const shortMonth = month => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]

  return months[month]
}

// Format Date into `D MMM` pattern, e.g. 1 Jan
export const formatDate = date => {
  const newDate = moment(date).tz("Europe/Rome").toDate()
  const day = newDate.getDate()
  const month = shortMonth(newDate.getMonth())
  const formattedDate = `${day} ${month}`

  return formattedDate
}

// Format Date into `D MMM YYYY` pattern, e.g. 1 Jan 1970
export const formatDateFullYear = date => {
  const newDate = moment(date).tz("Europe/Rome").toDate()
  const day = newDate.getDate()
  const month = shortMonth(newDate.getMonth())
  const year = newDate.getFullYear()
  const formattedDate = `${day} ${month} ${year}`

  return formattedDate
}

// Format Date into `D MMM 'YY` pattern, e.g. 1 Jan '70
export const formatDateShortYear = date => {
  const newDate = new Date(date)
  const day = newDate.getDate()
  const month = shortMonth(newDate.getMonth())
  const year = newDate.getFullYear().toString().substr(-2)
  const formattedDate = `${day} ${month} '${year}`

  return formattedDate
}

export const formatFullDate = date => {
  let newDate = new Date(Date.parse(date))
  newDate = add(newDate, { hours: 3 })
  newDate = newDate.toISOString()

  const formattedDate = newDate.slice(0, 10)

  return formattedDate
}

// Filter out dates with startDate ealier than today
export const filterDates = dates => {
  const today = new Date()

  return dates.filter(date => {
    const startDate = new Date(date.startDate)
    return startDate > today
  })
}

// Sort dates into ascending order by startDate
export const sortDates = dates => {
  const filteredDates = filterDates(dates)

  filteredDates.sort((a, b) => {
    const key1 = new Date(a.startDate)
    const key2 = new Date(b.startDate)

    if (key1 < key2) {
      return -1
    }
    if (key1 === key2) {
      return 0
    }

    return 1
  })

  return filteredDates
}

// Add specified number of days onto provided date
export const datePlusDays = (date, days) => {
  const d = days || 14
  const newDate = new Date(date)

  return new Date(newDate.setDate(newDate.getDate() + d))
}
