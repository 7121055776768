/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { motion, AnimatePresence } from 'framer-motion'
import { useStaticQuery, graphql } from 'gatsby'
import useAvailability from '../hooks/availability'

import '../styles/layout.css'

const Layout = ({ children, location }) => {
  const settings = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const { title } = settings.site.siteMetadata

  const { checkAvailability } = useAvailability()

  // Check availability immediately on component mount
  useEffect(() => checkAvailability(), [])

  // Check availability again every minute thereafter
  useEffect(() => {
    const interval = setInterval(() => checkAvailability(), 60000)

    return () => clearInterval(interval)
  })

  const duration = 0.15

  const variants = {
    initial: {
      opacity: 0.55,
    },
    enter: {
      opacity: 1,
      transition: {
        duration,
        delay: duration,
        when: 'beforeChildren',
      },
    },
    exit: {
      opacity: 0.55,
      transition: { duration },
    },
  }

  return (
    <div className="pb-1 bg-gray-100">
      <AnimatePresence>
        <motion.div
          data-component="layout-div"
          key={children.key}
          variants={variants}
          initial="initial"
          animate="enter"
          exit="exit"
        >
          <main>{children}</main>
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
}

export default Layout
