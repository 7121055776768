import React from 'react'

import { useGlobalStore } from '../stores/global'

const Availability = () => {
  const language = process.env.GATSBY_SITE_LANGUAGE
  const { available, localTime } = useGlobalStore()

  return (
    <div className="text-10 lg:text-12">
      <span
        className={`inline-block w-2 h-2 mr-1 leading-none rounded-full ${
          available ? 'bg-aqua-500' : 'bg-red-400'
        }`}
      />

      {language === 'en' && (
        <>
          {available ? 'Available' : 'Unavailable'} until {localTime}
        </>
      )}
      {language === 'it' && (
        <>
          {available ? 'Disponibile' : 'Non disponibile '} fino alle {localTime}
        </>
      )}
    </div>
  )
}

export default Availability
