import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import {
  FaInstagram,
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaLinkedinIn,
} from "react-icons/fa"

import SanityImage from "gatsby-plugin-sanity-image-alt"
import loadable from "@loadable/component"
import { useFooterContent } from "./FooterContent"
import { useMenuItems } from "./MenuItems"
import { useWindowSize } from "../../helpers/useWindowSize"

import FooterHills from "../../svg/footer-hills.svg"
import Phone from "../../svg/phone.svg"
import ContactFooter from "../../svg/contact-footer.svg"
import Availability from "../Availability"
import Shape from "../../svg/shape-footer.svg"

import { useSanityImageShape } from "../../helpers/useSanityImageShape"

const PortableText = loadable(() => import("../PortableText"))
const Menu = loadable(() => import("./Menu"))
const LowerFooter = loadable(() => import("./LowerFooter"))

const Footer = () => {
  const language = process.env.GATSBY_SITE_LANGUAGE
  const windowSize = useWindowSize()

  const getMenuItems = useMenuItems()
  const getFooterContent = useFooterContent()

  const headerData =
    language === "en" ? getFooterContent?.enHeader : getFooterContent?.itHeader
  const footerData =
    language === "en" ? getFooterContent?.enFooter : getFooterContent?.itFooter

  const menuData =
    language === "en" ? getMenuItems?.enFooter : getMenuItems?.itFooter

  const menuItems = menuData.nodes[0].menuColumns
  const tel = "+39 39 2522 2225"
  const { socialMediaLinks, image, _rawContent } = footerData

  const globalFooter = useStaticQuery(graphql`
    query {
      en: sanityGlobalFooter(i18n_lang: { eq: "en" }) {
        logo {
          asset {
            url
          }
          alt
        }
      }
      it: sanityGlobalFooter(i18n_lang: { eq: "it" }) {
        logo {
          asset {
            url
          }
          alt
        }
      }
    }
  `)

  const footer = language === "en" ? globalFooter?.en : globalFooter?.it

  const siteLogo = footer?.logo?.asset?.url
  const siteLogoAlt = footer?.logo?.alt

  const imageShaped = useSanityImageShape(image)

  return (
    <footer className="relative z-10 pt-16 bg-gray-100">
      <div className="">
        <div className="relative pb-16 bg-blue-500 md:pt-16 sm:pb-0">
          <div>
            <FooterHills className="absolute top-0 left-50 md:mx-auto translate-footer-hills" />
          </div>
        </div>
        <div className="p-4 text-white bg-blue-500">
          <div className="container grid grid-cols-12 mx-auto">
            <div className="col-span-12 xl:col-start-2 xl:col-span-10">
              <div className="md:flex md:my-12">
                <div className="pt-4 md:w-1/2 md:pt-0 md:pr-4 lg:pr-12">
                  <div className="text-white">
                    {_rawContent && (
                      <PortableText blocks={_rawContent} isFooter />
                    )}
                  </div>
                  <div className="mt-6">
                    <div className="flex items-center mb-6">
                      {windowSize.width < 768 && (
                        <a href={`tel:${tel}`}>
                          <Phone className="w-6 mr-6" />{" "}
                        </a>
                      )}
                      {windowSize.width >= 768 && (
                        <Phone className="w-6 mr-6" />
                      )}
                      <div>
                        <p className="font-bold leading-tight tracking-wide text-16">
                          {windowSize.width < 768 && (
                            <a href={`tel:${tel}`}>
                              {language !== "it" ? (
                                <>Call Us:</>
                              ) : (
                                <>Chiamaci:</>
                              )}{" "}
                              {tel}
                            </a>
                          )}
                          {windowSize.width >= 768 && (
                            <span>
                              {language !== "it" ? (
                                <>Call Us:</>
                              ) : (
                                <>Chiamaci:</>
                              )}{" "}
                              {tel}
                            </span>
                          )}
                        </p>
                        <div className="flex items-center text-12">
                          <Availability />
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center">
                      <ContactFooter className="w-6 mr-6" />
                      <Link
                        to={language !== "it" ? "/contact/" : "/contattaci/"}
                      >
                        <p className="font-bold leading-tight tracking-wide text-17">
                          {language !== "it" ? (
                            <>Ask us a question:</>
                          ) : (
                            <>Facci una domanda:</>
                          )}
                        </p>
                        <div className="flex items-center text-12">
                          {language !== "it" ? (
                            <>We answer within 24 hours</>
                          ) : (
                            <>Rispondiamo entro 24 ore</>
                          )}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="hidden md:w-1/2 md:pl-4 lg:pl-12 md:block">
                  {image && image.asset && (
                    <div className="relative text-blue-500">
                      <SanityImage
                        {...imageShaped}
                        className="relative z-10 w-full h-auto"
                        alt={image.alt}
                        width={525}
                        height={300}
                      />
                      <Shape className="absolute inset-0 z-10 w-full h-full" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="container grid grid-cols-12 mx-auto">
            <div className="col-span-12 pt-6 mt-6 border-t border-blue-200 xl:col-start-2 xl:col-span-10">
              <div className="flex flex-wrap items-center mb-8 lg:items-stretch md:my-12">
                <div className="hidden md:block sm:w-1/3 lg:w-1/5 sm:order-2">
                  {siteLogo && (
                    <div className="md:h-full md:flex md:items-center ">
                      <img
                        className="h-82px"
                        src={siteLogo}
                        alt={siteLogoAlt}
                        width="126"
                        height="82"
                      />
                    </div>
                  )}
                </div>
                <div className="flex w-full lg:w-3/5 sm:order-1 lg:order-2">
                  {menuItems &&
                    menuItems.map((menu, index) => (
                      <Menu
                        className={index === 1 ? "hidden md:block" : ""}
                        key={index}
                        menu={menu}
                      />
                    ))}
                </div>
                <div className="items-center justify-end hidden sm:flex sm:w-2/3 lg:block lg:w-1/5 sm:order-3 sm:py-4 lg:py-0">
                  <div className="inline-block">
                    <p className="mb-4 font-bold text-left text-14 sm:mb-0 sm:mr-4">
                      {language !== "it" ? <>Follow us</> : <>Seguici</>}
                    </p>
                    <div className="flex items-center -mx-2 text-blue-200 ">
                      {socialMediaLinks &&
                        socialMediaLinks.map((socialMediaLink, index) => {
                          let icon
                          let wrapperClasses = ""

                          switch (socialMediaLink.platform) {
                            case "Facebook":
                              wrapperClasses =
                                "w-10 h-10 border-4 border-blue-200 rounded-full flex items-center justify-center"
                              icon = <FaFacebookF className="w-6 h-6" />
                              break
                            case "Instagram":
                              icon = <FaInstagram className="w-10 h-10" />
                              break
                            case "Twitter":
                              icon = <FaTwitter className="w-10 h-10" />
                              break
                            case "Linkedin":
                              icon = <FaLinkedinIn className="w-10 h-10" />
                              break
                            case "Youtube":
                              icon = <FaYoutube className="w-10 h-10" />
                              break
                            default:
                              icon = null
                          }

                          return (
                            <div
                              key={socialMediaLink + index}
                              className="block p-2"
                            >
                              {icon && (
                                <a
                                  className={wrapperClasses}
                                  href={socialMediaLink.url}
                                  target="_blank"
                                  rel="nofollow noopener noreferrer"
                                >
                                  {icon}
                                </a>
                              )}
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <LowerFooter />
      </div>
    </footer>
  )
}

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
