exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-forms-js": () => import("./../../../src/pages/forms.js" /* webpackChunkName: "component---src-pages-forms-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-coaching-index-js": () => import("./../../../src/templates/Coaching/index.js" /* webpackChunkName: "component---src-templates-coaching-index-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-retreat-index-js": () => import("./../../../src/templates/Retreat/index.js" /* webpackChunkName: "component---src-templates-retreat-index-js" */),
  "component---src-templates-room-index-js": () => import("./../../../src/templates/Room/index.js" /* webpackChunkName: "component---src-templates-room-index-js" */)
}

