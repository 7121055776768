import { useMemo } from 'react'
// Problem using fragment when we're getting a huge object from _raw
export function useSanityImageShape(rawImage) {
  return useMemo(() => {
    if (!rawImage || !rawImage.asset) return false

    const imageShaped = rawImage

    imageShaped.asset._id = rawImage.asset._id
    imageShaped.asset.metadata = {
      preview: '',
    }

    return imageShaped
  }, [rawImage])
}
