import { Link, useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { motion } from 'framer-motion'

import loadable from '@loadable/component'
import { useHeaderNav } from '../../stores/HeaderNav'

import Shape from '../../svg/shape-nav.svg'

const HeaderNav = loadable(() => import('./HeaderNav'))
const HeaderNavMobile = loadable(() => import('./HeaderNavMobile'))

//  We're not using <header> here, that is reserved for content as per the SEO Guidelines -- Shane

const Header = ({ location }) => {
  const { menuVisible, setMenuVisible } = useHeaderNav()

  const logoData = useStaticQuery(graphql`
    query {
      globalHeader: sanityGlobalHeader {
        logo {
          asset {
            url
          }
          alt
        }
      }
    }
  `)

  const siteLogo = logoData.globalHeader.logo?.asset?.url
  const siteLogoAlt = logoData.globalHeader.logo?.alt

  return (
    <section data-component="header">
      <div
        className={`fixed top-0 left-0 z-30 w-full text-blue-500 transition bg-blue-500 ${menuVisible? 'bg-opacity-100' : 'bg-opacity-80'}`}
      >
        <div className="flex justify-start px-4 text-white">
          <Link to="/" className="relative z-10 siteLogo">
            {siteLogo && (
              <img src={siteLogo} alt={siteLogoAlt} width="86" height="56" />
            )}
            <span className="sr-only">{siteLogoAlt}</span>
          </Link>

          <HeaderNav location={location} />

          <button
            type="button"
            aria-label="Menu Button"
            onClick={() => setMenuVisible(!menuVisible)}
            className="relative flex-col justify-between w-6 h-6 my-auto ml-6 siteBurger"
          >
            <motion.span
              animate={{
                rotate: menuVisible ? 45 : 0,
                x: menuVisible ? -3 : 0,
                y: menuVisible ? 10 : 0,
                width: menuVisible ? '30px' : '1.5rem',
              }}
              className="block w-full h-1 bg-white rounded"
            />
            <motion.span
              animate={{
                opacity: menuVisible ? 0 : 1,
              }}
              className="block w-full h-1 bg-white rounded"
            />
            <motion.span
              animate={{
                rotate: menuVisible ? -45 : 0,
                x: menuVisible ? -3 : 0,
                y: menuVisible ? -10 : 0,
                width: menuVisible ? '30px' : '1.5rem',
              }}
              className="block w-full h-1 bg-white rounded"
            />
          </button>
        </div>
        <Shape
          className="transition-opacity duration-200 navShape"
          style={{ opacity: menuVisible ? '1' : '0.8' }}
        />
      </div>

      <HeaderNavMobile />
    </section>
  )
}

Header.propTypes = {
  location: PropTypes.object,
}

Header.defaultProps = {
  location: {},
}

export default Header
