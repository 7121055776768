import create from 'zustand'

export const useOverlayStore = create(set => ({
  overlayDisplay: false,
  setOverlayDisplay: overlayDisplay => set({ overlayDisplay }),

  // Form or Popup
  contentType: null,
  setContentType: contentType => set({ contentType }),

  title: null,
  setTitle: title => set({ title }),

  rate: null,
  setRate: rate => set({ rate }),

  body: null,
  setBody: body => set({ body }),

  closeOverlay: () =>
    set({
      overlayDisplay: false,
      contentType: null,
      title: null,
      body: null,
    }),
}))
