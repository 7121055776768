import { useStaticQuery, graphql } from 'gatsby'

export const useMenuItems = () =>
  useStaticQuery(graphql`
    query {
      enFooter: allSanityGlobalFooter(filter: {i18n_lang: {eq: "en"}}) {
        nodes {
          menuColumns {
            menuTitle
            menu {
              items {
                url
                title
              }
            }
          }
        }
      }
      itFooter: allSanityGlobalFooter(filter: {i18n_lang: {eq: "it"}}) {
        nodes {
          menuColumns {
            menuTitle
            menu {
              items {
                url
                title
              }
            }
          }
        }
      }
    }
  `)
