import React from 'react'
import { AnimatePresence } from 'framer-motion'
import loadable from '@loadable/component'
import { useLightboxStore } from '../../stores/lightbox'

const Lightbox = loadable(() => import('./Lightbox'))

const LightboxWrapper = () => {
  const { showGallery } = useLightboxStore()
  return <AnimatePresence>{showGallery && <Lightbox />}</AnimatePresence>
}

export default LightboxWrapper
