import moment from 'moment'
import 'moment-timezone'

import { useStaticQuery, graphql } from 'gatsby'
import { getToday, getTomorrow } from '../helpers/dates'
import { useGlobalStore } from '../stores/global'

export default function useAvailabilty() {
  const data = useStaticQuery(graphql`
    query {
      sanityGlobalSettings {
        _rawAvailablity
      }
    }
  `)

  // Get available hours for today
  const dayTimes = data.sanityGlobalSettings._rawAvailablity
  const today = getToday()
  const hours = dayTimes.find(obj => obj.day === today)

  // Convert hours into something that can be compared
  const start = new Date(`01/01/1970 ${hours.open}`)
  const startTime = start.getTime()
  const end = new Date(`01/01/1970 ${hours.close}`)
  const endTime = end.getTime()

  // Get available hours for tomorrow
  const tomorrow = getTomorrow()
  const tomHours = dayTimes.find(obj => obj.day === tomorrow)

  const checkLocalTime = time => {
    // Format time parameter to get UTC offset from Soul Valley timezone
    const svOffset = moment.tz(`1970-01-01 ${time}`, 'Europe/Rome').utcOffset()
    const offsetAsHours = svOffset / 60
    const offsetHours = parseFloat(time.slice(0, 2)) - offsetAsHours
    // Create new UTC from offset time
    const utcDateTime = new Date(`01/01/1970 ${offsetHours}:${time.slice(3)}`)

    // Get the local offset and convert to milliseconds
    const date = new Date()
    const localOffset = date.getTimezoneOffset() * 60 * 1000

    const localTimeStamp = utcDateTime.getTime() - localOffset
    const localTime = moment(localTimeStamp).format('h:mma')

    useGlobalStore.setState({ localTime })
  }

  const checkAvailability = () => {
    // Get time now in Central Europe
    const svTime = moment().tz('Europe/Rome').format('HH:mm')

    // Check if svTime is within available hours for today
    const current = new Date(`01/01/1970 ${svTime}`)
    const currentTime = current.getTime()

    // If current time is more than start time but less than end time set availability to try and set local time to today's closing time
    if (currentTime > startTime && currentTime < endTime) {
      useGlobalStore.setState({ available: true })
      checkLocalTime(hours.close)
    }
    // If current time is less than start time set local time to today's opening time
    if (currentTime < startTime) {
      useGlobalStore.setState({ available: false })
      checkLocalTime(hours.open)
    }

    // If current time is more than end timme set local time to tomorrow's start time
    if (currentTime > endTime) {
      useGlobalStore.setState({ available: false })
      checkLocalTime(tomHours.open)
    }
  }

  return { checkAvailability }
}
