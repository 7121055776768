import { useStaticQuery, graphql } from 'gatsby'

export const useFooterContent = () =>
  useStaticQuery(graphql`
    query {
      enHeader: sanityGlobalHeader(i18n_lang: {eq: "en"}) {
        tel
      }
      enFooter: sanityGlobalFooter(i18n_lang: {eq: "en"}) {
        _rawContent
        image {
          alt
          asset {
            _id
          }
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            id
          }
        }
        socialMediaLinks {
          url
          platform
        }
      }
      itHeader: sanityGlobalHeader(i18n_lang: {eq: "it"})  {
        tel
      }
      itFooter: sanityGlobalFooter(i18n_lang: {eq: "it"})  {
        _rawContent
        image {
          alt
          asset {
            _id
          }
          crop {
            _key
            _type
            top
            bottom
            left
            right
          }
          hotspot {
            _key
            _type
            x
            y
            height
            width
          }
          asset {
            id
          }
        }
        socialMediaLinks {
          url
          platform
        }
      }
    }
  `)
