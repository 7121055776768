import create from 'zustand'

export const useLightboxStore = create(set => ({
  showGallery: false,
  setShowGallery: showGallery => set({ showGallery }),

  images: null,
  setImages: images => set({ images }),

  closeCarousel: () =>
    set({
      showGallery: false,
      images: null,
    }),
}))
