import React from 'react'
import { AnimatePresence } from 'framer-motion'
import loadable from '@loadable/component'
import { useOverlayStore } from '../../stores/overlay'

const Content = loadable(() => import('./Content'))

const Overlay = () => {
  const { overlayDisplay } = useOverlayStore()

  return <AnimatePresence>{overlayDisplay && <Content />}</AnimatePresence>
}

export default Overlay
